canvas#filterCanvas {
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
}
canvas#backgroundCanvas {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
}
.canvas-area {
    width: 100%;
    max-height: max-content;
    height: 100%;
    min-height: 100vh;
    position: relative;
}
.no-select {
    -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
       -khtml-user-select: none; /* Konqueror HTML */
         -moz-user-select: none; /* Old versions of Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
              user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
}
.vertical-center {
    margin: 0;
    position: sticky;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}
.play-button {
    cursor: pointer;
    z-index: 4;
    opacity: 0.4;
    width: 120px;
    height: 120px;
    transition: opacity 300ms;
}
.play-button:hover {
    opacity: 0.7;
    transition: opacity 300ms;
}
.pause-button {
    cursor: pointer;
    z-index: 4;
    opacity: 0;
    transition: opacity 300ms;
    width: 120px;
    height: 120px;
}
@keyframes fade-out {
    from {opacity: 0.7}
    to {opacity: 0}
}
.pause-button:hover {
    transition: opacity 300ms;
    animation-name: fade-out;
    animation-duration: 2s;
}
.control-panel {
    position: sticky;
    z-index: 4;
    margin: 0;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.bottom-controls-1 {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 4;
    margin: 0;
    bottom: 170px;
    width: 100%;
    height: 60px;
}

.bottom-controls-2 {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 4;
    margin: 0;
    bottom: 120px;
    width: 100%;
    height: 50px;
}

.bottom-controls-3 {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 4;
    margin: 0;
    bottom: 80px;
    width: 100%;
    height: 50px;
}

.slide-left-enter {
    position: absolute;
    left: -100%;
}
.slide-left-enter-active {
    position: relative;
    left: 0;
    transition: left 1000ms;
}
.slide-left-exit {
    position: relative;
    left: 0;
}
.slide-left-exit-active {
    position: absolute;
    left: -100%;
    transition: left 1000ms;
}

.fade-enter {
    opacity: 0;
}
.fade-enter-active {
    opacity: 1;
    transition: opacity 1000ms;
}

.project-link-image {
    width: 100px;
    height: auto;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.follow-cursor-canvas {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    pointer-events: none;
}

.main-header-container {
    position: sticky;
    z-index: 4;
    margin: 0;
    top: 30%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}
.main-header {
    color: white;
    letter-spacing: 10px;
    opacity: 0.6;
}

.song-upload-button {
    margin-left: 50px;
}

.file-input::-webkit-file-upload-button {
    visibility: hidden;
}
.file-input::before {
    content: 'Visualize Your Audio!';
    font: 16px/30px 'opensans-bold', sans-serif;
    background: #444;
    display: inline-block;
    text-decoration: none;
    letter-spacing: 0;
    color: #fff;
    padding: 12px 20px;
    margin: auto;
    border: none;
    cursor: pointer;
    height: auto;
    -webkit-transition: all .2s ease-in-out;
    -moz-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    -ms-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    -moz-border-radius: 0px;
    -webkit-border-radius: 0px;
    -khtml-border-radius: 0px;
    border-radius: 0px;
    opacity: 0.6;
}
.file-input:hover::before {
    background: #fff;
    color: #2B2B2B;
}
.file-upload-input:active::before {
    margin-right: 15px;
    font-size: 20px;
}

.slider-icon-left {
    width: 30px;
    height: 30px;
    z-index: 4;
    margin-right: 125px;
    text-align: right;
    pointer-events: none;
}
.slider-icon-right {
    width: 30px;
    height: 30px;
    z-index: 4;
    margin-left: 125px;
    text-align: left;
    pointer-events: none;
}

.volume-bar {
    border-radius: 30px;
    -webkit-appearance: none;  /* Override default CSS styles */
    appearance: none;
    width: 320px;
    height: 30px; /* Specified height */
    background: #d3d3d3; /* Grey background */
    outline: none; /* Remove outline */
    -webkit-transition: .2s; /* 0.2 seconds transition on hover */
    transition: opacity 100ms;
    position: absolute;
}
.volume-bar::-webkit-slider-thumb {
    border-radius: 30px;
    -webkit-appearance: none; /* Override default look */
    appearance: none;
    width: 40px; /* Set a specific slider handle width */
    height: 40px; /* Slider handle height */
    background: RGB(233, 98, 38);
    cursor: pointer; /* Cursor on hover */
}
.volume-bar::-moz-range-thumb {
    width: 40px; /* Set a specific slider handle width */
    height: 40px; /* Slider handle height */
    background: #04AA6D; /* Green background */
    cursor: pointer; /* Cursor on hover */
}

.scrub-bar {
    border-radius: 30px;
    -webkit-appearance: none;  /* Override default CSS styles */
    appearance: none;
    width: 50%;
    min-width: 320px;
    height: 30px; /* Specified height */
    background: #d3d3d3; /* Grey background */
    outline: none; /* Remove outline */
    -webkit-transition: .2s; /* 0.2 seconds transition on hover */
    transition: opacity 100ms;
    position: absolute;
}
.scrub-bar::-webkit-slider-thumb {
    border-radius: 30px;
    -webkit-appearance: none; /* Override default look */
    appearance: none;
    width: 40px; /* Set a specific slider handle width */
    height: 40px; /* Slider handle height */
    background: RGB(233, 98, 38);
    cursor: pointer; /* Cursor on hover */
}
.scrub-bar::-moz-range-thumb {
    width: 40px; /* Set a specific slider handle width */
    height: 40px; /* Slider handle height */
    background: #04AA6D; /* Green background */
    cursor: pointer; /* Cursor on hover */
}

.social-media-links {
   padding-top: 48px;
   margin-bottom: 48px;
   color: #303030;
   font-size: 14px;
   position: relative;
   display: block;
}

